/* eslint-disable react/no-danger */
import { terms } from 'assets/terms'
import { SelectedAxeSignal, SelectedTubeSignal, useRoles } from 'services'
import { Button, FilterModal, ModalSignal, SwitchInput } from 'components'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg'
import { effect } from '@preact/signals-react'
import { LotViewModeSignal } from '../MissionsPage'
import { CreateMission } from '../modal'

import './MissionsHeader.scss'

export default function MissionsHeader() {
  const { isCat, isAdmin } = useRoles()
  const handleOpenModal = () => {
    if (!ModalSignal.value) ModalSignal.value = <FilterModal />
  }

  effect(() => {
    if (!SelectedAxeSignal.value || !SelectedTubeSignal.value) handleOpenModal()
  })

  const handleOpenModalCreateMission = () => {
    if (!ModalSignal.value) ModalSignal.value = <CreateMission />
  }

  return (
    <div className="missions-header">
      <div className="actions flex-center">
        {
          (isCat || isAdmin) && (
            <Button
              text={terms.Pages.Missions.addMission}
              icon={<PlusIcon />}
              onClick={handleOpenModalCreateMission}
            />
          )
        }
        <div className="filter" onClick={handleOpenModal}>
          <span>{`Matrice ${SelectedAxeSignal.value?.libelle || ''}`}</span>
          <span dangerouslySetInnerHTML={{ __html: SelectedTubeSignal.value?.libelle }} />
          <ChevronDown />
        </div>
        <SwitchInput
          label={terms.Pages.Missions.switchDisplayGroup}
          checked={LotViewModeSignal.value}
          onChange={value => { LotViewModeSignal.value = value }}
        />
      </div>

      <div className="week-days flex-center">
        {terms.Common.weekdays.map(day => (
          <div key={day} className="day">
            {day}
          </div>
        ))}
      </div>
    </div>
  )
}
