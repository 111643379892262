import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Paths } from 'types'
import { useRoles } from 'services'
import { ModalSignal } from 'components'
import {
  DeniedPage, BaremePage, BaremesPage, MissionPage, MissionsPage, FluxPage, FluxsPage,
} from 'pages'

export default function Router() {
  const { isBaremiste, isCat, isRcps } = useRoles()
  const location = useLocation()

  useEffect(() => {
    // reset root signals when changing route
    ModalSignal.value = undefined
  }, [location.pathname])

  // Home page is different based on user roles
  const getHomeElement = () => {
    if (isCat) return <MissionsPage />
    if (isRcps) return <FluxsPage />
    if (isBaremiste) return <BaremesPage />
    return <DeniedPage />
  }

  const catRoutes = isCat && (
    <Route element={<MissionPage />} path={Paths.MISSIONS_DETAILS} />
  )

  const baremeRoutes = isBaremiste && (
    <>
      <Route element={<BaremesPage />} path={Paths.BAREMES} />
      <Route element={<BaremePage />} path={Paths.BAREMES_DETAILS} />
    </>
  )

  const fluxRoutes = isRcps && (
    <>
      <Route element={<FluxsPage />} path={Paths.FLUX} />
      <Route element={<FluxPage />} path={Paths.FLUX_DETAILS} />
    </>
  )

  return (
    <Routes>
      <Route element={getHomeElement()} path={Paths.HOME} />
      {[isBaremiste, isCat, isRcps].some(role => role) && (
        <Route element={<MissionsPage />} path={Paths.MISSIONS} />
      )}
      {catRoutes}
      {fluxRoutes}
      {baremeRoutes}
      <Route path="*" element={<Navigate to={Paths.HOME} />} />
    </Routes>
  )
}
