import { ReactElement, useEffect, useState } from 'react'
import {
  Button, ButtonStyle, CheckInput, ImportModal, Loader, ModalSignal, TextInput,
} from 'components'
import ClearIcon from '@mui/icons-material/Clear'
import { MatrixSignal, StudyLotSignal, deleteStudyLot, updateStudyLotApi } from 'services'
import { v4 as uuidV4 } from 'uuid'
import terms from 'assets/terms'
import { sortDays, weeklyDays } from 'utils'
import { StudyLotType } from 'types'

import './UpdateLotMission.scss'
import { ModalType } from 'components/modals/ModalWrapper'

interface Props {
  subtitle: string
  sectionId: string
}

export default function UpdateLotMission({ subtitle, sectionId }: Props): ReactElement {
  const studyLot = StudyLotSignal.value
  const [currentLot, setCurrentLot] = useState<StudyLotType>()

  const currentStection = MatrixSignal.value?.results?.find(section => section.tronconId === sectionId)

  useEffect(() => {
    setCurrentLot(studyLot)
  }, [studyLot])

  if (!currentLot) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }
  /**
   * Function to close the modal and reset the new lot
  */
  const handleClose = () => {
    ModalSignal.value = null
  }

  /**
   * Function to update the new lot
   * @param key - key to update
   * @param value - value to update
  */
  const handleChange = <T, > (key: string, value: T) => {
    setCurrentLot({
      ...currentLot,
      [key]: value,
    })
  }

  /**
   * Function to update days of circulation of the lot
   * @param day - day to update
   * @returns string[]
  */
  const updateDays = (day: string) => {
    if (!currentLot.joursCirculation.length) {
      return [day]
    }
    if (currentLot.joursCirculation.includes(day)) {
      return currentLot.joursCirculation.filter(jour => jour !== day)
    }
    return currentLot.joursCirculation.concat(day).sort(sortDays)
  }

  /**
   * Function to update the lot
   * @returns void
  */
  const updateLot = () => {
    updateStudyLotApi(sectionId, currentLot).then(() => {
      handleClose()
    })
  }

  /**
   * Function to delete the study lot
   * @returns void
  */
  const handleDelete = () => {
    ModalSignal.value = (
      <ImportModal
        title={terms.Pages.Missions.StudyLot.Modal.DeleteLot.title(currentLot.libelle)}
        handleClickConfim={() => deleteStudyLot(sectionId, currentLot.id).then(() => {
          handleClose()
          StudyLotSignal.value = null
        })}
        type={ModalType.WARNING}
        textButton={terms.Common.continue}
      />
    )
  }

  /**
   * Check if all the fields are filled
  */
  const disabled = !Object.values(currentLot).every(val => {
    if (Array.isArray(val)) {
      return !!val.length
    }
    return !!val
  })

  const findMatrice = (i: number) => currentStection?.matrice.find(mat => mat.jour === (i + 1))

  return (
    <div className="update-lot-mission">
      <div className="header">
        <div className="title">
          <h2>{terms.Pages.Missions.StudyLot.Modal.UpdateLot.title}</h2>
          <ClearIcon onClick={handleClose} />
        </div>
        <h3>{subtitle}</h3>
      </div>
      <div className="form">
        <div className="inputs">
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.name}
            bindedValue={currentLot.libelle}
            onChange={val => handleChange('libelle', val)}
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.weight}
            bindedValue={currentLot.poids.toString()}
            onChange={val => handleChange('poids', val)}
            type="number"
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.length}
            bindedValue={currentLot.longueur.toString()}
            onChange={val => handleChange('longueur', val)}
            type="number"
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.wagons}
            bindedValue={currentLot.nombreWagons.toString()}
            onChange={val => handleChange('nombreWagons', val)}
            type="number"
          />
        </div>
        <div className="days-input">
          {weeklyDays.map((day, index) => (
            <CheckInput
              key={uuidV4()}
              label={day}
              defaultValue={currentLot.joursCirculation.includes(day)}
              onChange={() => handleChange<string[]>(
                'joursCirculation',
                updateDays(day),
              )}
              disabled={!findMatrice(index).capaciteLongueur && !findMatrice(index).capacitePoids}
            />
          ))}
        </div>
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={handleDelete}
          style={ButtonStyle.delete}
        />
        <Button
          text={terms.Common.validate}
          onClick={updateLot}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
