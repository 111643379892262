import { Link, useLocation } from 'react-router-dom'
import icodevLogo from 'assets/logos/d2dlogo.svg'
// Train animation SVG components
import { ReactComponent as SmokeTrain } from 'assets/icons/smoke-train.svg'
import { ReactComponent as SmokeFreeTrain } from 'assets/icons/smoke-free-train.svg'
import { ReactComponent as SmallSmokeTrain } from 'assets/icons/small-smoke-train.svg'
import terms from 'assets/terms'
import { PORTAL_URL } from 'config/config'
import { Paths } from 'types'
import { useRoles } from 'services'
import { useEffect, useState } from 'react'
import AccountMenu from './menu/MenuAccount'

import './TopBar.scss'

type Props = {
  appName: string;
}

enum Keywords {
  APT = 'apt',
  STOP = 'stop',
}

export default function TopBar({ appName }: Props) {
  const { isBaremiste, isCat, isRcps } = useRoles()
  const [isDisplayingAnimation, setIsDisplayingAnimation] = useState<boolean>(false)
  const [isListening, setIsListening] = useState<boolean>(false)
  const location = useLocation()

  // Get the active path based on the current location and user roles
  const getActivePath = () => {
    // if path is '/', we need to attribute the right path based on user roles
    if (location.pathname === Paths.HOME) {
      if (isCat) return Paths.MISSIONS
      if (isRcps) return Paths.FLUX
      if (isBaremiste) return Paths.BAREMES
    } else {
      return location.pathname
    }

    return ''
  }

  // Displays available paths based on user roles in the right order
  const getPathsOrder = () => {
    const paths = []
    if (isCat) paths.push(Paths.MISSIONS)
    if (isRcps) paths.push(Paths.FLUX, Paths.MISSIONS)
    if (isBaremiste) paths.push(Paths.BAREMES, Paths.MISSIONS)

    return Array.from(new Set(paths))
  }

  useEffect(() => {
    let keySequence: string[] = []

    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase()

      if (key === 'control') {
        setIsListening(true)
        keySequence = []
      }

      if (isListening && key !== 'control') {
        keySequence.push(key)
        keySequence = keySequence.slice(-5)

        switch (keySequence.join('')) {
          case Keywords.STOP:
            setIsDisplayingAnimation(false)
            setIsListening(false)
            break
          case Keywords.APT:
            setIsDisplayingAnimation(true)
            setIsListening(false)
            break
          default:
            break
        }
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'control') {
        setIsListening(true)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [isListening])

  return (
    <header>
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={icodevLogo} />
        </a>
        <h1>{appName}</h1>
      </div>
      <nav>
        {getPathsOrder().map(path => (
          <Link
            key={path}
            to={path}
            className={getActivePath().includes(path) ? 'active' : 'inactive'}
          >
            {terms.Header.nav[path]}
          </Link>
        ))}
      </nav>
      {
        isDisplayingAnimation && (
          <div className="animation-train">
            <div className="separation" />
            <div className="animate-logo">
              <SmokeFreeTrain className="locomotive image1" />
              <SmallSmokeTrain className="locomotive image2" />
              <SmokeTrain className="locomotive image3" />
            </div>
            <div className="separation" />
          </div>
        )
      }
      <AccountMenu />
    </header>
  )
}
