import ArrowRightIcon from '@mui/icons-material/ArrowForward'
import terms from 'assets/terms'
import { Matrice } from 'types'
import { formatHour } from 'utils'
import { SectionLot } from './SectionLot'
import { LotViewModeSignal } from '../MissionsPage'

import './SectionCard.scss'

interface Props {
  day: Matrice
  onOpenUpdateLot: (id: string, sectionId: string) => void
  sectionId: string
}

export default function SectionCard({ day, onOpenUpdateLot, sectionId }: Props) {
  if (!day.horaireArrivee) {
    return (
      <div className="section-card">
        <div className="wrapper" />
      </div>
    )
  }

  const info = {
    [terms.Pages.Missions.section.remplissage]: `${day.remplissage}%`,
    [terms.Pages.Missions.section.weight]: `${day.capacitePoids}t | ${day.capaciteLongueur}m`,
    [terms.Pages.Missions.section.hours]: (
      <p className="hours">
        <span>
          {formatHour(day.horaireDepart)}
        </span>
        <ArrowRightIcon />
        <span>
          {formatHour(day.horaireArrivee)}
        </span>
      </p>
    ),
    [terms.Pages.Missions.section.train]: (day.numeroSillon.map(val => (
      <span key={val}>
        {val}
      </span>
    ))),
    [terms.Pages.Missions.section.addValue]: `${day.cumulPoids}t | ${day.cumulLongueur}m`,
    [terms.Pages.Missions.section.restValue]: `${day.residuelPoids}t | ${day.residuelLongueur}m`,
  }

  /**
   * Function to add alert class to the section card
   * @param label string
   * @param alert boolean
   * @returns string
   */
  const getClassName = (label: string, alert: boolean): string => (
    (label === terms.Pages.Missions.section.addValue && alert) ? 'alert' : '')

  /**
   * Function to display the lot
   * @param isStudyLot boolean
   * @returns boolean
  */
  const displayingLot = (isStudyLot: boolean) => {
    if (isStudyLot) return LotViewModeSignal.value
    return true
  }

  return (
    <div className="section-card">
      <div className={`wrapper ${day.alerteCapacite ? 'alert-capacity' : ''}`}>
        <div className="alert-info">
          <span>
            {terms.Pages.Missions.section.overcapacity}
          </span>
        </div>
        <div>
          <div className="info">
            {Object.entries(info).map(([key, value]) => (
              <div
                key={key}
                className={
                  `${key === terms.Pages.Missions.section.train
                    ? 'train-wrapper'
                    : ''}${getClassName(key, day.alerteCapacite)}`
                  }
              >
                <div>{key}</div>
                <div className={`${key === terms.Pages.Missions.section.train ? 'trains' : ''}`}>{value}</div>
              </div>
            ))}
          </div>
          <div className="lots flex-column-center">
            {day.lots.map(lot => (
              displayingLot(lot.isEtude) && (
              <SectionLot onOpenUpdateModal={onOpenUpdateLot} key={lot.id} lot={lot} sectionId={sectionId} />
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
