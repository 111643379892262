import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'

/**
 * Get the error message from an error object
 * @param error - The error object
 * @returns string
 */
export const getErrorMessage = (error: {status: number}) => {
  switch (error.status) {
    case 400:
      return terms.Error.httpStatus.badRequest
    case 401:
      return terms.Error.httpStatus.forbidden
    case 403:
      return terms.Error.httpStatus.forbidden
    case 404:
      return terms.Error.httpStatus.notFound
    case 502:
      return terms.Error.httpStatus.badGateway
    default:
      return terms.Error.httpStatus.unknownError
  }
}

/**
 * Function to find the deepest value in an object
 * @param obj - The object to search in
 * @returns string
 */
const findDeepestValueInData = obj => {
  let deepestValue = null
  let maxDepth = -1

  // Fonction to search recursively in the object
  const recursiveSearch = (currentObj, depth) => {
    // If the current object is an object, search in it
    if (typeof currentObj === 'object' && currentObj !== null) {
      // If the current object is an array, search in each item
      if (Array.isArray(currentObj)) {
        currentObj.forEach(item => recursiveSearch(item, depth + 1))
        // If the current object is a string, check if it's the deepest value
      } else {
        Object.entries(currentObj).forEach(([, value]) => {
          recursiveSearch(value, depth + 1)
        })
      }
    } else if (depth > maxDepth) {
      maxDepth = depth
      deepestValue = currentObj
    }
  }

  // Start the search
  if (obj?.data) {
    recursiveSearch(obj.data, 0)
  }

  return deepestValue
}

/**
 * Catch errors from an async function and display a toast error message
 *
 * @param fn - The function to catch errors from
 * @returns The result of the function or an error object
 */
export const catchable = async<T> (
  fn: () => Promise<T>,
  displayToast?: boolean,
  errorMessage?: string,
) => {
  try {
    return await fn()
  } catch (e) {
    if (displayToast) {
      ToastSignal.value = {
        message: errorMessage || findDeepestValueInData(Object.values(e)[2])?.toString() || getErrorMessage(e),
        severity: ToastLevel.ERROR,
      }
    }

    return { error: e }
  }
}
