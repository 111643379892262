import { ReactElement } from 'react'
import { ReactComponent as WeightIcon } from 'assets/icons/weight.svg'
import { ReactComponent as SizeIcon } from 'assets/icons/size.svg'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as TrainIcon } from 'assets/icons/train.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { Lot } from 'types'
import { useRoles } from 'services'

interface Props {
  lot: Lot
  onOpenUpdateModal: (id: string, sectionId) => void
  sectionId: string
}

export function SectionLot({ lot, onOpenUpdateModal, sectionId }: Props): ReactElement {
  const { isCat, isAdmin } = useRoles()

  const isEditable = (isCat || isAdmin) && lot.isEtude
  return (
    <div
      className={`lot${lot.isEtude ? ' study' : ''}`}
      key={lot.id}
      onDoubleClick={() => isEditable && onOpenUpdateModal(lot.id, sectionId)}
    >
      <div className="title flex-center">
        <div className="libelle">
          <h4>{lot.libelle}</h4>
          {
            isEditable && (
              <button type="button" onClick={() => onOpenUpdateModal(lot.id, sectionId)}>
                <EditIcon />
              </button>
            )
          }
        </div>
        <BurgerIcon />
      </div>
      <div className="details flex-center">
        <div>
          <WeightIcon />
          {`${lot.poids}t`}
        </div>
        <div>
          <SizeIcon />
          {`${lot.longueur}m`}
        </div>
        <div>
          <TrainIcon />
          {`${lot.nombreWagons}wg`}
        </div>
      </div>
    </div>
  )
}
