import { ReactElement, useState } from 'react'
import { Button, CheckInput, ModalSignal, TextInput } from 'components'
import ClearIcon from '@mui/icons-material/Clear'
import { createStudyLot } from 'services/cat/studyLots'
import { v4 as uuidV4 } from 'uuid'
import terms from 'assets/terms'
import { sortDays, weeklyDays } from 'utils'
import { NewLot } from 'types'
import { MatrixSignal } from 'services'

import './AddLotMissionModal.scss'

interface Props {
  subTitle: string
  sectionId: string
}

const DEFAULT_NEW_LOT: NewLot = {
  libelle: '',
  poids: 0,
  longueur: 0,
  nombreWagons: 0,
  joursCirculation: [],
  isEtude: true,
}

export default function AddLotMissionModal({ subTitle, sectionId }: Props): ReactElement {
  const [newLot, setNewLot] = useState<NewLot>(DEFAULT_NEW_LOT)
  const currentStection = MatrixSignal.value?.results?.find(section => section.tronconId === sectionId)

  /**
   * Function to handle the change of the input
   * @param key string
   * @param value T
  */
  const handleChange = <T, > (key: string, value: T) => {
    setNewLot({
      ...newLot,
      [key]: value,
    })
  }

  /**
   * Function to close the modal and reset the new lot
  */
  const handleClose = () => {
    setNewLot(DEFAULT_NEW_LOT)
    ModalSignal.value = null
  }

  /**
   * Create the new lot
  */
  const createLot = () => {
    createStudyLot(sectionId, newLot).then(() => {
      handleClose()
    })
  }

  const disabled = !Object.values(newLot).every(val => {
    if (Array.isArray(val)) {
      return !!val.length
    }
    return !!val
  })

  const updateDays = (day: string) => {
    if (!newLot.joursCirculation.length) {
      return [day]
    }
    if (newLot.joursCirculation.includes(day)) {
      return newLot.joursCirculation.filter(jour => jour !== day)
    }
    return newLot.joursCirculation.concat(day).sort(sortDays)
  }

  const findMatrice = (i: number) => currentStection?.matrice.find(mat => mat.jour === (i + 1))

  return (
    <div className="add-lot-mission">
      <div className="header">
        <div className="title">
          <h2>{terms.Pages.Missions.StudyLot.Modal.CreateLot.title}</h2>
          <ClearIcon onClick={handleClose} />
        </div>
        <h3>{subTitle}</h3>
      </div>
      <div className="form">
        <div className="inputs">
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.name}
            bindedValue={newLot.libelle}
            onChange={val => handleChange('libelle', val)}
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.weight}
            bindedValue={newLot.poids.toString()}
            onChange={val => handleChange('poids', val)}
            type="number"
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.length}
            bindedValue={newLot.longueur.toString()}
            onChange={val => handleChange('longueur', val)}
            type="number"
          />
          <TextInput
            label={terms.Pages.Missions.StudyLot.Modal.CreateLot.wagons}
            bindedValue={newLot.nombreWagons.toString()}
            onChange={val => handleChange('nombreWagons', val)}
            type="number"
          />
        </div>
        <div className="days-input">
          {weeklyDays.map((day, index) => (
            <CheckInput
              key={uuidV4()}
              label={day}
              defaultValue={newLot.joursCirculation.includes(day)}
              onChange={() => handleChange<string[]>(
                'joursCirculation',
                updateDays(day),
              )}
              disabled={!findMatrice(index).capaciteLongueur && !findMatrice(index).capacitePoids}
            />
          ))}
        </div>
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.save}
          onClick={createLot}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
